import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getScreenSize } from 'helpers/HtmlHelpers';
import timedFetch from 'middleware/timedFetch';
import { trackError } from 'helpers/ErrorUtils';
import type { RequestSponsoredAdsBody, RequestSponsoredAdsBodyFilters, SponsoredAdsResponse, SponsoredAdsResults } from 'apis/sponsoredAds';
import { getSponsoredAds } from 'apis/sponsoredAds';
import useMartyContext from 'hooks/useMartyContext';
import {
  AD_PLACEMENT_COUNTS,
  BOTTOM_ROW_RETURNED_AD_AMOUNT,
  engineAdsPriorityOrder,
  ENGINES,
  TOP_ROW_RETURNED_AD_AMOUNT
} from 'constants/sponsoredAds';
import type { AppState } from 'types/app';

export const trackSponsoredAdImpressions = (
  { url, fetcherName = 'SponsoredAdImpressions' }: { url: string; fetcherName?: string },
  fetcher = timedFetch
) => {
  const fetch = fetcher(fetcherName);
  fetch(url).catch((error: any) => trackError('ERROR', 'Failed to send MSFT impression events', error));
};

function concatIfExists(keyword = '', val?: any) {
  if (val) {
    //purposeful whitespace.
    return `${keyword} ${val}`;
  }
  return keyword;
}

// builds the keyword for search implementation based off filters.
// keyword should be "gender + most specific zc + brand[0] + color[0]"
export function buildKeywordFromFilters(filters: Partial<RequestSponsoredAdsBodyFilters>) {
  //find most specific zc- 'zc1', 'zc2', etc.
  const mostSpecificZcKey = Object.keys(filters).reduce((val, current) => {
    if (current.includes('zc')) {
      //ex: zc2 > zc1
      return current > val ? current : val;
    }

    return val;
  }, '') as keyof RequestSponsoredAdsBodyFilters;

  //all of these are optional on the filters object, if they exist add it to the keyword.
  let keyword = filters.txAttrFacet_Gender?.[0] || '';
  keyword = concatIfExists(keyword, filters[mostSpecificZcKey]?.[0]);
  keyword = concatIfExists(keyword, filters.brandNameFacet?.[0]);
  keyword = concatIfExists(keyword, filters.colorFacet?.[0]);
  return keyword;
}

//configure the payload for the get request to sponsoredAds endpoint
//handle the response and return the results.
export function fetchSponsoredAds(
  args: Omit<RequestSponsoredAdsBody, 'clientType'>,
  sponsoredAdsUrl: string,
  controller: AbortController,
  trackAdImpression = trackSponsoredAdImpressions
): Promise<SponsoredAdsResponse> {
  const body = {
    clientType: getScreenSize(),
    ...args
  };

  return getSponsoredAds(body, sponsoredAdsUrl, controller).then((results: SponsoredAdsResponse) => {
    (args.engines || []).forEach(engine => {
      const engineResults = results[engine];

      if (engineResults) {
        const { impressionUrl } = engineResults;

        if (impressionUrl) {
          trackAdImpression({ url: impressionUrl });
        }

        if (engineResults.results?.top || engineResults.results?.bottom) {
          const allResults = [...(engineResults.results.top || []), ...(engineResults.results.bottom || [])];

          allResults.forEach(result => {
            if (result.impressionUrl) {
              trackAdImpression({ url: result.impressionUrl });
            }
          });
        }
      }
    });

    return results;
  });
}

type UseSponsoredAds = Pick<RequestSponsoredAdsBody, 'pageType'> & Partial<Pick<RequestSponsoredAdsBody, 'keywords'>>;

//usage in PDP
type UseSponsoredAdsWithStockId = UseSponsoredAds & Required<Pick<RequestSponsoredAdsBody, 'stockId' | 'keywords'>>;

//usage in Search
type UseSponsoredAdsWithFilters = UseSponsoredAds &
  Required<Pick<RequestSponsoredAdsBody, 'filters'>> &
  Pick<RequestSponsoredAdsBody, 'placements' | 'term'> & { matchStyles?: string[] };

function isUseSponsoredAdsWithFilters(args: UseSponsoredAdsWithStockId | UseSponsoredAdsWithFilters): args is UseSponsoredAdsWithFilters {
  return (args as UseSponsoredAdsWithFilters).filters !== undefined;
}

export const useSponsoredAds = (args: UseSponsoredAdsWithStockId | UseSponsoredAdsWithFilters, fetchAds = fetchSponsoredAds) => {
  const [sponsoredAds, setSponsoredAds] = useState<SponsoredAdsResults>();
  const sessionId = useSelector((state: AppState) => state.cookies?.['session-id']);
  const { title: titleTag, canonical: url } = useSelector((state: AppState) => state.meta?.documentMeta) ?? {}; //documentMeta can be null
  const {
    marketplace: {
      search: { hasSponsoredAds }
    },
    environmentConfig: { api: { sponsoredAds: { url: sponsoredAdsUrl = '' } = {} } = {} } = {}
  } = useMartyContext();

  // PIQ has been made permanent. Other engines added conditionally based on a feature flag can be pushed into the array.
  const engines: (keyof typeof ENGINES)[] = [ENGINES.PIQ];

  // require all pieces of information before fetching sponsoredAds.
  useEffect(() => {
    // early return if no marketplace support or missing required pieces from state that are async changes via other actions.
    if (!hasSponsoredAds || !sessionId || !args.pageType || !titleTag || !url || engines.length === 0) return;

    // we need either or of these so if they dont exist, return;
    if (!(args as UseSponsoredAdsWithStockId).stockId && !(args as UseSponsoredAdsWithFilters).filters) return;

    const payload = {
      sessionId,
      titleTag,
      url,
      placements: AD_PLACEMENT_COUNTS,
      engines,
      ...args
    } as RequestSponsoredAdsBody;

    if (isUseSponsoredAdsWithFilters(args)) {
      payload.keywords = [buildKeywordFromFilters(args.filters)];
    }

    const controller = new AbortController();
    fetchAds(payload, sponsoredAdsUrl, controller).then(data => {
      let adData: SponsoredAdsResults | undefined;

      engineAdsPriorityOrder.forEach(engine => {
        const allResults = [...(adData?.results?.top || []), ...(adData?.results?.bottom || [])];

        if (adData?.results && allResults.length !== 0) {
          return;
        }

        adData = data[engine];

        if (adData?.results) {
          if (adData.results.top?.length > TOP_ROW_RETURNED_AD_AMOUNT) {
            adData.results.top = [...adData.results.top.slice(0, TOP_ROW_RETURNED_AD_AMOUNT)];
          }

          if (adData.results.bottom?.length > BOTTOM_ROW_RETURNED_AD_AMOUNT) {
            adData.results.bottom = [...adData.results.bottom.slice(0, BOTTOM_ROW_RETURNED_AD_AMOUNT)];
          }
        }
      });

      setSponsoredAds(adData);
    });

    return () => {
      controller.abort();
    };
  }, [sessionId, titleTag, url, JSON.stringify(args)]);

  return sponsoredAds;
};
