import { ProductCarousel } from '@mweb/zappos-ui/ProductCarousel';

interface SponsoredBrandsCarouselProps {
  heading: string | null;
  slides: React.ReactNode[];
}

const SponsoredBrandsCarousel = (props: SponsoredBrandsCarouselProps) => {
  const { heading, slides } = props;

  return (
    <section className="flex flex-col gap-4">
      <h2 className="text-xl/none font-semibold lg:text-2xl/none">{heading}</h2>

      <ProductCarousel overrides={{ root: '[--slide-gap:12px]' }} options={{ slidesToScroll: 1 }} slides={slides} />
    </section>
  );
};

export default SponsoredBrandsCarousel;
