import timedFetch from 'middleware/timedFetch';
import { fetchErrorMiddleware } from 'middleware/fetchErrorMiddleware';
import type { Product } from 'constants/searchTypes';

export type RequestSponsoredAdsBody = {
  sessionId: string;
  clientIp?: string;
  clientType: string;
  url: string;
  titleTag: string;
  term?: string;
  keywords: string[];
  pageType: string;
  recommendationType?: string;
  stockId?: string;
  filters?: Partial<RequestSponsoredAdsBodyFilters>;
  placements?: AdPlacementLocation;
  engines?: string[];
  matchStyles?: string[];
};

export type AdPlacementLocation = {
  top: AdPlacementLocationCount;
  bottom: AdPlacementLocationCount;
};

export type AdPlacementLocationCount = {
  min: number;
  max: number;
};

export type RequestSponsoredAdsBodyFilters = {
  brandNameFacet: string[];
  brandId: string[];
  colorFacet: string[];
  hc_footwear_infant_toddler_youth_size: string[];
  hc_men_size: string[];
  hc_women_size: string[];
  onSale: string[];
  price: number[];
  priceFacet: number[];
  realBrandNameFacet: string[];
  txAttrFacet_Features: string[];
  txAttrFacet_Gender: string[];
  txAttrFacet_Occasion: string[];
  txAttrFacet_SleeveLength: string[];
  txAttrFacet_Styles: string[];
  txAttrFacet_Verticals: string[];
  zc1: string[];
  zc2: string[];
  zc3: string[];
  zc4: string[];
  zc5: [string];
};

export interface SponsoredAdsResults {
  impressionUrl: string;
  results: SponsoredAdsResultsSplit;
}

export interface SponsoredAdsResultsSplit {
  top: SponsoredAdProduct[];
  bottom: SponsoredAdProduct[];
}

export interface SponsoredAdsResponse {
  [engine: string]: SponsoredAdsResults;
}

export interface SponsoredAdProduct extends Product {
  viewUrl: string;
  impressionUrl: string;
}

export function getSponsoredAds(
  body: RequestSponsoredAdsBody,
  sponsoredAdsUrl: string,
  controller: AbortController,
  fetcher = timedFetch('getSponsoredAds')
): Promise<SponsoredAdsResponse> {
  const opts = {
    method: 'post',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Accept': '*/*'
    },
    body: JSON.stringify(body),
    signal: controller.signal
  };

  return fetcher(`${sponsoredAdsUrl}/sponsored-ads/engines`, opts).then(fetchErrorMiddleware);
}
